// STATUS
export const FERTIG = 'fertig';
export const BAU = 'bau';
export const GEPLANT = 'geplant';

// STATUUS
export const STATUS = [FERTIG, BAU, GEPLANT];

// GET PROJECT STATUS
export const getProjectStatus = status => {
    switch (status) {
        case FERTIG:
            return 'Fertiggestellt';
        case BAU:
            return 'Im Bau';
        case GEPLANT:
            return 'In Planung';
        default:
            return 'kein Status';
    }
};
