import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
const STUTTGART = 'Stuttgart';

export default ({ setActiveStandort, activeStandortId, standorte, baubueros, parentStandort }) => {
    let stuttgart = {};
    const sortedStandorte = standorte
        .filter(standort => {
            if (standort.name === STUTTGART) {
                stuttgart = standort;
                return false;
            } else {
                return true;
            }
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    sortedStandorte.unshift(stuttgart);
    return (
        <div className="">
            <div className="row align-items-center mb-4">
                <div className={' col-12 '}>
                    <ul className="nav nav-pills w-100 justify-content-end">
                        {sortedStandorte.map((standort, index) => {
                            let classname = '';
                            if (activeStandortId === standort.id) {
                                classname = 'active ernst-blue';
                            }
                            if (parentStandort && parentStandort === standort.id) {
                                classname = 'active ernst-blue';
                            }
                            return (
                                <li
                                    className={`nav-item nav-link font-weight-bold c-pointer text-uppercase ml-1 ${classname} ${
                                        index === 0 ? ' mr-auto ' : ''
                                    } `}
                                    key={standort.id}
                                    value={standort.id}
                                    onClick={() => setActiveStandort(standort)}>
                                    {standort.name}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            <div className="w-100">
                <div className="row">
                    <div className={'position-relative mb-4 col-lg-4 ml-lg-auto'}>
                        <Typeahead
                            selected={baubueros.filter(baubuero => baubuero.id == activeStandortId)}
                            placeholder={'Baubüro auswählen'}
                            options={baubueros}
                            clearButton={true}
                            className={'typeahead'}
                            onChange={selected => {
                                if (selected.length !== 0) {
                                    setActiveStandort(selected[0]);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
