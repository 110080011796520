export const getQueryVariable = (variable, defaultVar = null) => {
    const query = window.location.hash.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return defaultVar;
};
