import React from 'react';

export default ({ isSelection, activeStandort }) => {
    if (activeStandort && /\d/.test(activeStandort.strasse) === false) {
        activeStandort.strasse += ' ' + activeStandort.hnr;
    }
    return (
        <div className="card standort-info-block">
            <div className="card-body">
                {isSelection && activeStandort !== null && (
                    <>
                        <p className="mb-0 text-uppercase">Standort</p>
                        <p>{activeStandort.name}</p>

                        <p className="mb-0 text-uppercase">Adresse</p>
                        <p>
                            {activeStandort.strasse}
                            <br />
                            {activeStandort.plz} {activeStandort.stadt}
                        </p>

                        <p className="mb-0 text-uppercase">Kontakt</p>
                        <p>
                            {activeStandort.tel && (
                                <>
                                    <span className="tel position-relative">
                                        <a className="ml-4" href={'tel:' + activeStandort.tel}>
                                            {activeStandort.tel}
                                        </a>
                                    </span>
                                    <br />
                                </>
                            )}
                            {activeStandort.fax && (
                                <>
                                    <span className="fax position-relative">
                                        <a className="ml-4" href={'tel:' + activeStandort.fax}>
                                            {activeStandort.fax}
                                        </a>
                                    </span>
                                    <br />
                                </>
                            )}

                            {activeStandort.mail && (
                                <>
                                    <span className="email position-relative">
                                        <a className="ml-4" href={'mailto:' + activeStandort.mail}>
                                            {activeStandort.mail}
                                        </a>
                                    </span>
                                </>
                            )}
                        </p>
                    </>
                )}
                {!isSelection && <>Bitte wählen Sie einen Standort aus oder nutzen Sie die Suchfunktion um ein Projektbüro zu finden</>}
            </div>
        </div>
    );
};
