$(document).ready(function () {

    var navbar = document.getElementById("naviagtion-block");
    var sticky = document.getElementById("main").offsetTop;

    if (!document.getElementById('carouselExampleIndicators')) {
        navbar.classList.add("sticky");
    } else {
        $(window).on('scroll',  () => {
            if (window.pageYOffset >= sticky) {
                navbar.classList.add("sticky");
            } else {
                navbar.classList.remove("sticky");
            }
        });
    }

});
