import React from 'react';
import ReactDOM from 'react-dom';

import StandortWidget from './StandortWidget/';
import ProjekteWidget from './ProjekteWidget';

$(() => {
    if (document.getElementById('widget--standorte')) {
        const { mitarbeiternachaktuellenarbeitskonditionen } = document.getElementById('widget--standorte').dataset;
        const widgetData = {
            mitarbeiterNachAktuellenArbeitsKonditionen: JSON.parse(mitarbeiternachaktuellenarbeitskonditionen),
        };
        ReactDOM.render(<StandortWidget widgetData={widgetData} />, document.getElementById('widget--standorte'));
    }
});

$(() => {
    if (document.getElementById('widget--projekte')) {
        const { images, categories, subcategories, bauaufgaben, projects, hauptstandorte, standorte, urls } = document.getElementById(
            'widget--projekte'
        ).dataset;
        const widgetData = {
            categories: JSON.parse(categories),
            subCategories: JSON.parse(subcategories),
            bauAufgaben: JSON.parse(bauaufgaben),
            projects: JSON.parse(projects),
            hauptStandorte: JSON.parse(hauptstandorte),
            standorte: JSON.parse(standorte),
            projectUrls: JSON.parse(urls),
            images: JSON.parse(images),
        };
        ReactDOM.render(<ProjekteWidget widgetData={widgetData} />, document.getElementById('widget--projekte'));
    }
});
