import React from 'react';
import Tab from './Tab';
import { STANDORT_TYP_BAUBUERO, TAB_ANSPRECHPARTNER, TAB_BAUBUEROS, TAB_PROJEKTE } from '../StandortWidget';

export default ({ children, standort, activeTab, setActiveTab }) => (
    <div className="tabs">
        <ul className="nav-tabs nav">
            {standort().type !== STANDORT_TYP_BAUBUERO && (
                <React.Fragment>
                    <Tab tabLabel={TAB_ANSPRECHPARTNER} setActiveTab={label => setActiveTab(label)} activeTab={activeTab} />
                    <Tab tabLabel={TAB_BAUBUEROS} setActiveTab={label => setActiveTab(label)} activeTab={activeTab} />
                </React.Fragment>
            )}
            <Tab tabLabel={TAB_PROJEKTE} setActiveTab={label => setActiveTab(label)} activeTab={activeTab} />
        </ul>
        <div className="tab-content">
            {children.map((child, i) => child && child.props.id === activeTab && <div key={`child_${child.props.id}_${i}`}>{child}</div>)}
        </div>
    </div>
);
