Storage.prototype.setObject = function(key, value) {
    this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function(key, defaultValue = null) {
    var value = this.getItem(key);
    try {
        return JSON.parse(value);
    } catch (err) {
        console.log('JSON parse failed for lookup of ', key, '\n error was: ', err);
        return defaultValue;
    }
};
