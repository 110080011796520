import React, { Component } from 'react';
import * as Filter from './filters';
import * as Sortings from './sortings';

export default class Projekte extends Component {
    render() {
        const { projekte } = this.props;
        if (this.props.projekte.length <= 0) {
            return <li className={'link-unstyled'}>Keine zugeordneten Projekte</li>;
        }
        return projekte.sort(Sortings.SORT_ALPHABETICALLY).map((projekt, index, array) => {
            let className = '';
            let lastFirstLetter = '';
            let currentFirstLetter = projekt.name.charAt(0);
            if (index > 0) {
                lastFirstLetter = array[index - 1].name.charAt(0);
            }
            if (currentFirstLetter.match(/^-{0,1}\d+$/) && lastFirstLetter.match(/^-{0,1}\d+$/)) {
                className = '';
            } else {
                if (currentFirstLetter === lastFirstLetter) {
                    className = '';
                } else {
                    className = ' mt-3 ';
                }
            }
            return (
                <li key={projekt.id} className={`link-unstyled ${className}`}>
                    <a className={'link-unstyled'} href={projekt.link} title={projekt.name}>
                        {projekt.name}
                    </a>
                </li>
            );
        });
    }
}
