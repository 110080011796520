import React from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import * as Filters from './filters';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';

const Map = withScriptjs(
    withGoogleMap(({ standorte, baubueros, clickBaubueroMarker, clickStandortMarker, isSelection }) => {
        const baubuerosGroup = groupBaubueros(baubueros);

        // determine bounds from all passed standorte to display card properly
        const bounds = new window.google.maps.LatLngBounds();
        standorte.forEach(standort => bounds.extend(new window.google.maps.LatLng(standort.lat, standort.lng)));
        return (
            <GoogleMap ref={map => map && map.fitBounds(bounds)} options={{ styles: GOOGLE_MAPS_STYLES, maxZoom: 13 }}>
                {standorte.map((standort, i) => (
                    <MarkerStandort
                        key={`standort_${i}`}
                        standort={standort}
                        click={clickStandortMarker}
                        pin={standort.type === 'Baubüro' ? 'standort' : 'zentrale'}
                    />
                ))}
                {isSelection &&
                    baubuerosGroup.map((data, i) => (
                        <UnclusteredMarkers
                            key={`data_${i}`}
                            baubueros={data.baubueros}
                            click={clickBaubueroMarker}
                        />
                    ))}
            </GoogleMap>
        );
    })
);

export default props => (
    <Map
        googleMapURL={process.env.GOOGLE_MAPS_API_URL}
        loadingElement={<div className="h-100" />}
        containerElement={<div style={{ height: `600px` }} />}
        mapElement={<div className="h-100" />}
        {...props}
    />
);

// groups baubueros by parent id
const groupBaubueros = standorte => {
    // create structure for baubueros displayed in a markercluster
    const baubuerosGroup = {};
    standorte.forEach(standort => {
        if (typeof baubuerosGroup[standort.parent] === 'undefined') {
            baubuerosGroup[standort.parent] = [];
        }
        baubuerosGroup[standort.parent].push(standort);
    });

    return Object.keys(baubuerosGroup).map(parentId => ({
        parentId,
        baubueros: baubuerosGroup[parentId],
    }));
};

const UnclusteredMarkers = ({ baubueros, click }) =>
    baubueros.map((standort, i) => <MarkerStandort key={`standort_baubuero_${i}`} standort={standort} click={click} pin="baubuero" />);

const MarkerStandort = ({ standort, click, pin }) => (
    <Marker
        key={standort.id}
        position={{ lat: standort.lat, lng: standort.lng }}
        onClick={() => click(standort)}
        title={standort.name}
        icon={`/files/Platzhalter/Map/${pin}-pin.svg`}
    />
);
const GOOGLE_MAPS_STYLES = [
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e9e9e9',
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5',
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 29,
            },
            {
                weight: 0.2,
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 18,
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5',
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dedede',
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#ffffff',
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        elementType: 'labels.text.fill',
        stylers: [
            {
                saturation: 36,
            },
            {
                color: '#333333',
            },
            {
                lightness: 40,
            },
        ],
    },
    {
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f2f2f2',
            },
            {
                lightness: 19,
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#fefefe',
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#fefefe',
            },
            {
                lightness: 17,
            },
            {
                weight: 1.2,
            },
        ],
    },
];
