$(document).ready(function () {
    if (document.getElementById('carousel-indicator')) {

        var my_width = document.getElementById('carousel-indicator').offsetWidth;

        var len = document.getElementsByClassName('carousel-counter').length;

        if (len > 2) {
            var x = document.getElementsByClassName('carousel-counter');
            for (var i = 0; i < x.length; i++) {
                x[i].style.width = my_width + "px";
            }
        }
    }


    $('.slider').each(function () {
        let $carousel = $(this).find('.carousel');
        const totalItems = $(this).find('.carousel-item').length;
        $carousel
            .on('slid.bs.carousel', () => {
                var currentIndex = $(this).find('div.active').index() + 1;
                $(this).find('.carousel-counter').html(`${currentIndex} / ${totalItems}`);

                let $active = $carousel.find('.carousel-item.active');
                $carousel.find('.text-text').html($active.data('text'));
                $carousel.find('.text-headline').html($active.data('headline'));
            })
            .trigger('slid.bs.carousel');
    });

});
