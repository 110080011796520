import React, { Component } from 'react';

const CLOSED = -1;

export default class extends Component {
    static Item = ({ headline, children, doOpen, open = false }) => (
        <div className={`card accordion-item ${!open && 'collapsed'}`}>
            {headline && (
                <div className="card-header" onClick={doOpen}>
                    <div className="accordion-icon" />
                    <h5 className="mb-0">{headline}</h5>
                </div>
            )}
            <div className={`collapse ${open && 'show'}`}>
                <div className="card-body accordion-item-body">{children}</div>
            </div>
        </div>
    );

    state = {
        open: CLOSED,
    };

    doOpen = i => this.setState({ open: i === this.state.open ? CLOSED : i });

    componentWillReceiveProps() {
        this.doOpen(CLOSED);
    }

    render() {
        const children = React.Children.map(this.props.children, (childElement, i) => {
            return React.cloneElement(childElement, {
                open: this.state.open === i,
                doOpen: () => this.doOpen(i),
            });
        });

        return <div className={`accordion-parent ${this.props.className}`}>{children}</div>;
    }
}
