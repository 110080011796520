export const SORT_ALPHABETICALLY = (a, b) => {
    if (a.name) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
    } else if (a.label) {
        if (a.label < b.label) {
            return -1;
        }
        if (a.label > b.label) {
            return 1;
        }
    }
    return 0;
};
